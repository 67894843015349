<template>
  <v-dialog v-model="modal.toggle" persistent width="30%">
    <v-card>
      <v-container>
        <OrganizzeModalHeader
          :loading="modal.loading"
          :isEdit="modal.isEdit"
          :title="modal.title"
          @onClose="closeModal()"
        />

        <v-radio-group v-model="isSubcategory">
          <v-radio
            v-for="item in [{ name: 'Categoria principal', isSubcategory: false }, { name: 'Subcategoria', isSubcategory: true }]"
            :key="item.name"
            :label="item.name"
            :value="item.isSubcategory">
          </v-radio>
        </v-radio-group>

        <v-row>
          <v-col :md="!isSubcategory ? 10 : 12">
            <v-text-field
              v-model="category.name"
              label="Nome" outlined
              :error-messages="categoryNameErrors"
              @blur="$v.category.name.$touch()">
            </v-text-field>
          </v-col>

          <v-col v-if="!isSubcategory" md="2">
            <CustomColorPicker :initialColor="category.colour" @onClick="onSelectColor"/>
          </v-col>
        </v-row>

        <v-autocomplete
          v-if="isSubcategory"
          v-model="category.categoryId"
          label="Categoria pai"
          append-icon="mdi-magnify"
          item-text="name"
          item-value="id"
          :items="fatherCategoriesByGroup"
          :error-messages="fatherCategoryErrors"
          @blur="$v.category.categoryId.$touch()"
          clearable
          outlined
          filled
          dense>
          <template v-slot:item="data">
            <v-list-item-avatar size="24px" :style="`background: ${data.item.colour};`"> </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-radio-group v-if="modal.isEdit" v-model="category.categoryGroup">
          <v-radio
            v-for="(groupName, index) in categoryGroupsEnum"
            :key="index"
            :label="categoryGroupsEnum[index]"
            :value="index">
          </v-radio>
        </v-radio-group>

        <OrganizzeModalFooter @onConfirm="enviar" :loading="modal.loading"/>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { mapActions, mapGetters } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import validation from '../validation'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import { categoryGroupsEnum } from '@/utils/tempUtils'
import { isValidParam } from '@/utils/validationUtils'

const namespaceStore = 'organizzeFinanceiroCategorias'

export default {
  name: 'OrganizzeFinanceiroCategoriasModalCadastro',

  data: (() => ({
    modal: {
      toggle: false,
      isEdit: false,
      loading: false,
      title: ''
    },
    isSubcategory: false,
    category: {
      id: null,
      name: '',
      colour: '#00FF00',
      categoryId: null,
      categoryGroup: null
    },
    fatherCategoriesByGroup: []
  })),

  components: {
    CustomColorPicker: () => import('@/views/components/customColorSwatches'),
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header'),
    OrganizzeModalFooter: () => import('@/views/components/organizze/modal/footer')
  },

  watch: {
    'category.categoryGroup' () {
      const self = this
      self.fatherCategoriesByGroup = self.categoryGroupsMapped[self.category.categoryGroup]
    },
    'isSubcategory' (data) {
      const self = this
      if (!data) self.category.categoryId = null
    }
  },

  computed: {
    ...mapGetters(namespaceStore, ['categoryGroupsMapped']),
    ...validation,
    categoryGroupsEnum: categoryGroupsEnum
  },

  created () {},

  mounted () {
    const self = this

    Events.$on('modalCadastro::open', data => {
      self.modal.toggle = true
      self.modal.title = categoryGroupsEnum()[data.categoryGroup]
      self.category.categoryGroup = data.categoryGroup
    })

    Events.$on('modalCadastro::edit', data => {
      self.modal.toggle = true
      self.modal.isEdit = true
      self.modal.title = data.category.name
      self.isSubcategory = isValidParam(data.category.categoryId)
      self.category = {
        id: data.category.id,
        name: data.category.name,
        colour: data.category.colour,
        categoryId: data.category.categoryId,
        categoryGroup: data.category.categoryGroup
      }

      Events.$emit('customColorSwatcher::init', { initialColor: data.category.colour })
    })
  },

  methods: {
    ...mapActions(namespaceStore, ['submit']),

    onSelectColor (data) {
      const self = this
      self.category.colour = data.color
    },

    enviar () {
      const self = this

      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const data = {
        ...self.category,
        isEdit: self.modal.isEdit
      }

      if (self.isSubcategory) {
        data.categoryId = self.category.categoryId
        data.colour = null
      }

      if (!data.isEdit) {
        data.jsonFlags = {
          "costing": false,
          "InportOnly": false
        }
      }

      self.modal.loading = true
      self.submit(data).then(() => {
        if (!self.modal.isEdit) successSnackbar('Categoria adicionada com sucesso.')
        else successSnackbar('Categoria editada com sucesso.')
        Events.$emit('index::getCategories')
        self.closeModal()
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.modal.loading = false
      })
    },

    closeModal () {
      const self = this
      self.modal = {
        toggle: false,
        isEdit: false,
        loading: false,
        title: ''
      }
      self.isSubcategory = false
      self.category = {
        id: null,
        name: '',
        colour: '#00FF00',
        categoryId: null,
        categoryGroup: null
      }
      self.fatherCategoriesByGroup = []
    },
  },

  validations () {
    return {
      category: {
        name: { required },
        categoryId: { required: requiredIf(() => this.isSubcategory) }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }

  .toolBar {
    background-color: $colorPrimary !important;
    border-radius: 16px !important;
    display: flex;
    justify-content: center;
    margin: 0px;
    color: white;

    &-text {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
</style>